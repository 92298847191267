import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'code', 'field' ]

  connect() {
    this.codeTarget.addEventListener('keydown', (e) => {
      if (e.key == 'Enter') {
        this.search()
        e.preventDefault()
        return false
      }
    })
  }

  search() {
    const code = this.codeTarget.value
    fetch(`/apis/zipcodes?z=${code}`)
    .then(response => response.json())
    .then(json => {
      const table = ['zip', 'prefecture_name', 'city', 'street']
      let data = []
      table.forEach(name => {
        if (json[name] && json[name].length > 0) {
          data.push(json[name] + ' ')
        }
      })
      const address = data.join('')

      this.fieldTarget.value = address
      if (address.length > 0) {
        this.fieldTarget.focus({preventScroll:true})
      }
    })
  }
}
