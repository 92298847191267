import 'javascripts/pack_header';


$(function() {
  $('#sm-menu-open').on('click', function() {
    var element = $('body > .container-fluid > .row > [class*="col-"]:first-child');
    element.css({
      'display':  'block'
    });
  });
  $('#sm-menu-close').on('click', function() {
    var element = $('body > .container-fluid > .row > [class*="col-"]:first-child');
    element.css({
      'display':  'none'
    });
  });

  $('.global-notices .alert').fadeIn(function() {
    var alert = $(this);
    setTimeout(function() {
      alert.fadeOut();
    }, 10000);
  });
});
