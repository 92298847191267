import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'check', 'collapse' ]

  connect() {
    this.toggle()
  }

  toggle() {
    const checked = this.checkTarget.checked
    this.collapseTargets.forEach(collapse => {
      if (checked) {
        collapse.classList.add('d-none')
      } else {
        collapse.classList.remove('d-none')
      }
    })
  }
}
