import { Controller } from "stimulus"
import { Sortable, OnSpill, AutoScroll } from 'sortablejs'

export default class extends Controller {
  static targets = []

  connect() {
    this.element.addEventListener('cocoon:after-insert', (e) => {
      if (e.target.classList.contains('add-question')) {
        this.indexing()
      }
    })
    this.element.addEventListener('cocoon:after-remove', (e) => {
      if (e.target.classList.contains('add-question')) {
        this.indexing()
      }
    })

    this.buildSortable()
  }

  indexing() {
    this.element.querySelectorAll('.question-nested-fields').forEach((field, index) => {
      let sortIndexField = field.querySelector('input[type="hidden"][name*="[sort_index]"]')
      sortIndexField.setAttribute('value', index)
    })
  }

  buildSortable() {
    const questionSortable = new Sortable.create(
      this.element, {
        draggable:      '.question-nested-fields',
        filter:         '.add-question',
        handle:         '.question-handle',
        animation:      250,
        revertOnSpill:  true,
        onUpdate: (event) => {
          this.indexing()
        }
      }
    )
  }
}
