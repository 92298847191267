import { Controller } from "stimulus"
import { Sortable, OnSpill, AutoScroll } from 'sortablejs'

export default class extends Controller {
  static targets = [ 'slides' ]

  connect() {
    this.element.addEventListener('cocoon:after-insert', (e) => {
      if (e.target.classList.contains('add-slide')) {
        this.indexing()
      }
    })
    this.element.addEventListener('cocoon:after-remove', (e) => {
      if (e.target.classList.contains('add-slide')) {
        this.indexing()
      }
    })

    this.buildSortable()
  }

  indexing() {
    this.element.querySelectorAll('.slide-nested-fields').forEach((field, index) => {
      let sortIndexField = field.querySelector('input[type="hidden"][name*="[sort_index]"]')
      sortIndexField.setAttribute('value', index)
    })
  }

  buildSortable() {
    const sortable = new Sortable.create(
      this.slidesTarget, {
        draggable:      '.slide-nested-fields',
        filter:         '.add-slide',
        handle:         '.slide-handle',
        animation:      250,
        revertOnSpill:  true,
        onUpdate: (event) => {
          this.indexing()
        }
      }
    )
    return sortable
  }
}
