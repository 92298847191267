/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("trix")
require("@rails/actiontext")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require.context('../images', true, /\.(png|jpg|jpeg|svg)$/);


import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import 'controllers'
import '@oddcamp/cocoon-vanilla-js'

$(function() {
  var circle_icon_base  = '<i class="fas fa-circle fa-stack-2x text-success"></i>';
  var circle_icon_times = '<i class="fas fa-times fa-stack-2x fa-inverse p-3"></i>';
  var circle_icon_bars  = '<i class="fas fa-bars fa-stack-2x fa-inverse p-3"></i>';

  $('#sm-menu-open').on('click', function() {
    var circle  = $(this).find('.sheet .circle');
    var element = $('body > nav');
    if (element.hasClass('sm')) {
      element.removeClass('sm');
    } else {
      element.addClass('sm');
    }
    circle.empty();
    circle.append(circle_icon_base);
    if (element.hasClass('sm')) {
      circle.append($(circle_icon_times));
    } else {
      circle.append($(circle_icon_bars));
    }
  });
    $('#sm-menu-open .sheet .circle').append(circle_icon_base);
  $('#sm-menu-open .sheet .circle').append($(circle_icon_bars));

  $('.global-notices .alert').fadeIn(function() {
    var alert = $(this);
    setTimeout(function() {
      alert.fadeOut();
    }, 10000);
  });
});
